import React from "react";
import HeroShop from "./HeroShop";
import Navbar from "./Navbar";
import Collections from "./Collections";
import Categories from "./Categories";
import bottomBanner from "../assests/bottom-banner.png";
import Footer from "./Footer";

const Home = () => {
  return (
    <>
      <div className="font-poppins ">
        <Navbar showSearch={true} />
        <HeroShop />
        <Collections />
        <Categories />
        <div className="container">
          <div className="flex  mt-[-12rem] z-50">
            <img src={bottomBanner} alt="" className="w-full z-50" />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
