import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PolicyCustomer from '../../components/PolicyCustomer';
import DeleteAccountPrivacy from '../../components/DeleteAccountPrivacy';
import { useAuth } from '../../auth/authContext';

const CustomerWebsite = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      {isAuthenticated ? (
        <></>
      ) : (
        <>
          <Route path="/privacy-policy" element={<PolicyCustomer />} />
          <Route
            path="/delete-account-privacy"
            element={<DeleteAccountPrivacy />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      )}
    </Routes>
  );
};

export default CustomerWebsite;
