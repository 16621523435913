import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";

const DeleteAccountPrivacy = () => {
  return (
    <>
    <Navbar />
    {/* Steps to delete a Tajammul Customer account */}
    <div className="container mx-auto my-8 mt-[10rem] px-4 font-poppins"
        style={{ marginTop: 50 }}
        >
      <h1 className="text-2xl font-bold mb-4">Steps to Delete a Tajammul Customer Account</h1>
      <ol className="list-decimal list-inside my-4">
        <li>Register your account and verify your email address.</li>
        <li>Login to your account to access the dashboard screen displaying listed products.</li>
        <li>Click on the top-left drawer icon to access account options and settings.</li>
        <li>Within the settings, click on the '+' icon to reveal the "Delete Account" option with a trash icon.</li>
        <li>Click on the "Delete Account" button. A warning popup will appear asking if you're sure to delete.</li>
        <li>If confirmed, your account will be successfully deleted.</li>
      </ol>
    </div>
    
    {/* Steps to delete a Tajammul Business account */}
    <div className="container mx-auto my-8 mt-[10rem] px-4 font-poppins">
      <h1 className="text-2xl font-bold mb-4">Steps to Delete a Tajammul Business Account</h1>
      <ol className="list-decimal list-inside my-4">
        <li>Register your account as a business user and verify your email address.</li>
        <li>Login to your account to create a shop by adding necessary details.</li>
        <li>Upon logging in, the dashboard will appear. Set shop or business timings first.</li>
        <li>Click on the top-left drawer icon to access account options and settings.</li>
        <li>Within the settings, click on the '+' icon to reveal the "Delete Account" option with a trash icon.</li>
        <li>Click on the "Delete Account" button. A warning popup will appear asking if you're sure to delete.</li>
        <li>If confirmed, your account will be successfully deleted.</li>
      </ol>
    </div>

    <Footer />
  </>
  )
}

export default DeleteAccountPrivacy;
