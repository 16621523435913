import React, { useState } from 'react';
import Logo from '../../constants/svg/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setAuthorizedHandler, setUserInfo } from '../../store/reducers/user';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Toaster from '../../common/Toaster';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({
    status: false,
    type: '',
    message: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit =async (event) => {
    event.preventDefault();
    const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
    try {
      const apiURL = `${API_BASE_URL}/oauth/token`;
      const headers = {
        'Authorization': 'Basic ' + btoa('socol:topsecret'),
        'Content-Type': 'application/x-www-form-urlencoded'
      };
      const body = new URLSearchParams({
        'grant_type': 'password',
        'username': username,
        'password': password
      });
      
      const response = await fetch(apiURL, {
        method: 'POST',
        headers: headers,
        body: body
      });
  
      if (!response.ok) {
        if (response.status === 400) {
          setError({
            status: true,
            type: 'error',
            message: 'Invalid Credentials',
          });
          return ;
        } else if (response.status === 401) {
          setError({
            status: true,
            type: 'error',
            message: 'Something went wrong please try again',
          });
          return;
        } else {
          setError({
            status: true,
            type: 'error',
            message: 'Server error please try later',
          });
          return ;
        }
      }
      const responseData = await response.json();
      const userCredentials = {
        'username': username,
        'password': password,
        'access_token': responseData.access_token,
        'refresh_token': responseData.refresh_token
      };
    dispatch(setAuthorizedHandler(true));
    dispatch(setUserInfo(userCredentials))
    navigate('/');
    } catch (error) {
      console.error(error);
      setError({
        status: true,
        type: 'error',
        message: 'Something went wrong please try again',
      });
      return "Something went wrong please try again";
    }
  };

  return (
    <div className="bg-white mx-auto my-8 mt-[10rem] px-4 font-poppins">
      {error.status && <Toaster {...error} changeError={setError} />}

      <Logo className="mx-auto mb-4" width="1750" height="130" />
      <h4 className="text-center  mb-5 text-xl text-F16427" style={{marginLeft: 200}}>Shopping Made Easy</h4>
      <h3 className="text-center  mb-5 text-xl text-273B60">Sign in to your account and get access <br/>to every store.</h3>


    <div className="form-container w-full max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
    <h4 className="text-center  mb-8 text-xl text-273B60">Sign in</h4>
      
      <form onSubmit={handleSubmit} className="w-full">
        <div className="mb-4">
          <label htmlFor="username" className="block text-sm font-medium text-gray-700">
            Username/Email *
          </label>
          <input
            type="text"
            id="username"
            className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Enter username or email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
        Password *
      </label>
      <div className="relative">
        <input
          type={showPassword ? 'text' : 'password'}
          id="password"
          className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {/* Toggle button */}
        <button
          type="button"
          className="absolute inset-y-0 right-0 px-3 py-2 bg-transparent focus:outline-none"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <FaEyeSlash className="h-5 w-5 text-gray-500" /> : <FaEye className="h-5 w-5 text-gray-500" />}
        </button>
      </div>
             <div className="mt-3 text-left text-blue-700">

          <Link to="/reset-password" className='text-273B60' style={{textDecoration: 'underline'}}>
          Forgot Password
            </Link>
        </div>
        </div>
     
        <button
          type="submit"
          className="w-full py-2 rounded-lg bg-[#F16427] text-white font-medium hover:bg-[#C6471A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F16427]"
        >
          Sign in
        </button>
        <div className="mt-4 text-center">
        Don't have an account?
        &nbsp;
        <Link to="/signup" className='text-273B60' style={{textDecoration: 'underline'}}>
        Sign up
            </Link>
        
      </div>
      </form>
    </div>

   
  </div>
  
  
  
  
  );
}

export default Login;
