import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './Navbar'; // Replace with your Navbar component
import Footer from './Footer'; // Replace with your Footer component
import { GET_PRODUCT_BY_IDENTIFIER } from '../GraphQL/mutation';
import client from '../GraphQL/ApolloClient';
import ProductCard from './ProductCard';
import Loader from '../common/Loader/Loader';

const ProductDetails = () => {
  const { slug } = useParams();
  const [products, setProducts] = useState({});
  const [loader, setLoader] = useState(true);

  async function fetchProductByIdentifier(identifier) {
    try {
      const response = await client.query({
        query: GET_PRODUCT_BY_IDENTIFIER,
        variables: { identifier },
      });

      const productData = response.data.getProductByIdentifier.data;
      setProducts(productData);
      setLoader(false);
      return productData;
    } catch (error) {
      console.error('Error fetching product by identifier:', error);
      throw error; // Propagate the error if you want to handle it elsewhere
    }
  }

  const callFunc = async () => {
    fetchProductByIdentifier(slug);
  };

  useEffect(() => {
    callFunc();
  }, [slug]);

  return (
    <div className="font-poppins">
      <Helmet>
        <title>
          {products?.productName ? products.productName : 'Product Details'}
        </title>
        <meta
          name="description"
          content={
            products?.description ? products.description : 'Product Details'
          }
        />
        <meta
          property="og:title"
          content={
            products?.productName ? products.productName : 'Product Details'
          }
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={
            products?.listImagePath && products.listImagePath.length > 0
              ? `https://storage.googleapis.com/awkat-o-salat/${products.listImagePath[0]}`
              : 'https://dummyimage.com/qvga'
          }
        />
        {/* <meta property="og:url" content={window.location.href} /> */}
        <meta
          property="og:url"
          content={window.location.pathname + window.location.search}
        />

        <meta
          property="og:description"
          content={
            products?.description ? products.description : 'Product Details'
          }
        />
        {/* <meta property="og:site_name" content="European Travel, Inc." /> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content="Alt text for image" />
      </Helmet>

      <Navbar showSearch={true} />
      {loader && <Loader />}
      {!loader && <ProductCard productData={products} />}
      <Footer />
    </div>
  );
};

export default ProductDetails;
