import React, { useState } from 'react';
import { FaPlus, FaMinus, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import 'tailwindcss/tailwind.css';
import { FaTicketAlt } from 'react-icons/fa';

const ProductDetails = ({ productData }) => {
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);
  const [showDetails, setShowDetails] = useState(false); // State for showing more details

  const handleQuantityChange = (change) => {
    setQuantity((prev) => Math.max(1, prev + change));
  };

  const handleImageChange = (index) => {
    setSelectedImage(index);
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const businessInfo = productData?.business?.businessInfo;
  const businessTiming = productData?.business?.timings?.timings;
  const images = productData?.listImagePath?.map(
    (imageName) => `https://storage.googleapis.com/awkat-o-salat/${imageName}`,
  );
  const businessImage = businessInfo?.listImagePath?.map(
    (imageName) => `https://storage.googleapis.com/awkat-o-salat/${imageName}`,
  );

  const nextImage = () => {
    setSelectedImage((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setSelectedImage(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  }

  return (
    <div className="container mx-auto py-16">
      <div className="bg-white shadow-md rounded-lg overflow-hidden mb-4">
        <h2 className="text-2xl font-semibold text-gray-800 p-4 border-b border-gray-300">
          Product Details
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="p-4 flex flex-col items-center">
            <div className="relative w-3/4 mb-4">
              <img
                src={images[selectedImage]}
                alt="Product"
                className="w-full rounded-lg mr-4"
              />
              <button
                onClick={prevImage}
                className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white border border-gray-300 rounded-full p-2"
              >
                <FaArrowLeft />
              </button>
              <button
                onClick={nextImage}
                className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white border border-gray-300 rounded-full p-2"
              >
                <FaArrowRight />
              </button>
            </div>
            <div className="flex justify-center">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`thumbnail ${index + 1}`}
                  className={`w-12 h-12 mx-1 cursor-pointer ${
                    selectedImage === index
                      ? 'border-2 border-blue-600'
                      : 'border border-gray-300'
                  }`}
                  onClick={() => handleImageChange(index)}
                />
              ))}
            </div>
          </div>
          <div className="p-4">
            <h5 className="text-xl font-semibold mb-2">
              {productData.productName}
            </h5>
            <p className="text-gray-600 mb-4">{productData.description}</p>
            <p className="text-gray-600 mb-4">Status: {productData.status}</p>
            <p className="text-gray-600 mb-4">
              Category: {productData?.category?.categoryName}
            </p>
            <h6 className="text-lg font-semibold mt-4">Ingredients</h6>
            {/* <h4 className="text-red-500 text-2xl mt-2">
              Rs. {productData.discountedPrice}
            </h4> */}
            <p className="text-gray-600 mt-1">
              <strike>${productData.price}</strike> -{' '}
              {productData.discount || 10}% off
            </p>
            <div className="flex items-center mt-3">
              <p className="mr-2">Size Family</p>
              <button className="border border-gray-300 rounded px-2 py-1 mx-1">
                S
              </button>
              <button className="border border-gray-300 rounded px-2 py-1 mx-1">
                M
              </button>
              <button className="border border-gray-300 rounded px-2 py-1 mx-1">
                L
              </button>
            </div>
            <div className="flex items-center mt-3">
              <p className="mr-2">Quantity</p>
              <button
                onClick={() => handleQuantityChange(-1)}
                className="border border-gray-300 rounded px-2 py-1 mx-1"
              >
                <FaMinus />
              </button>
              <input
                type="text"
                value={quantity}
                readOnly
                className="border border-gray-300 rounded text-center w-12"
              />
              <button
                onClick={() => handleQuantityChange(1)}
                className="border border-gray-300 rounded px-2 py-1 mx-1"
              >
                <FaPlus />
              </button>
            </div>
            <div className="flex mt-4">
              <button className="bg-blue-600 text-white rounded px-4 py-2 mr-10">
                Buy Now
              </button>
              <button className="bg-yellow-500 text-white rounded px-4 py-2">
                Add To Cart
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Delivery Information Card */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-2">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Delivery Information
            </h2>
          </div>
          <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-2">
            <div>
              <p className="text-gray-600">Delivery</p>
              <small className="text-gray-500">
                {businessInfo.address.formatedAddress}
              </small>
            </div>
            <button className="text-blue-600">Change</button>
          </div>
          <div className="border-b border-gray-300 pb-2 mb-2">
            <p className="text-gray-600">Free Delivery 26 Mar - 31 Mar</p>
            <small className="text-gray-500">5 - 10 day(s) Free</small>
          </div>
          <div className="border-b border-gray-300 pb-2 mb-2">
            <p className="text-gray-600">Cash on Delivery Available</p>
          </div>
          <div className="border-b border-gray-300 pb-2 mb-2">
            <p className="text-gray-600">Service</p>
            <small className="text-gray-500">
              14 days free & easy return. Change of mind is not applicable
            </small>
          </div>

          <div className="border-b border-gray-300 pb-2 mb-2">
            <p className="text-gray-600">Warranty not available</p>
          </div>
        </div>
        {/* Business Information Card */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <div className="mb-4">
            <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-2">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                Store Information
              </h2>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-2">
              <p className="text-lg font-regular">{businessInfo?.name}</p>
              <p className="text-gray-600 flex items-center">
                <FaTicketAlt
                  className={`mr-2 ${businessInfo?.isOpened ? 'text-green-500' : 'text-red-500'}`}
                />
                <span className="font-semibold">Open Status:</span>{' '}
                {businessInfo?.isOpened ? (
                  <span className="text-green-500"> Opened</span>
                ) : (
                  <span className="text-red-500"> Closed</span>
                )}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-2">
            <div>
              <p className="text-gray-600">Address</p>
              <small className="text-gray-500">
                {businessInfo?.address?.formatedAddress}
              </small>
            </div>
          </div>
          <div className="border-b border-gray-300 pb-2 mb-2">
            <ul className="mt-4">
              <li className="flex justify-between items-center text-gray-600 mb-1">
                <span>Positive Seller Ratings</span>
                <span>70%</span>
              </li>
              <li className="flex justify-between items-center text-gray-600 mb-1">
                <span>Ship OnTime</span>
                <span>86%</span>
              </li>
              <li className="flex justify-between items-center text-gray-600 mb-1">
                <span>Chat Response Time</span>
                <span>95%</span>
              </li>
            </ul>
          </div>
          <div className="flex mt-4 justify-between">
            <button className="border border-blue-600 text-blue-600 rounded px-2 py-1 w-small mr-2">
              Visit Store
            </button>
            <button
              onClick={() => setShowDetails(true)}
              className="bg-blue-600 text-white rounded px-2 py-1 w-small"
            >
              More Details
            </button>
          </div>
        </div>
      </div>
      {/* Detailed Store Information (conditionally rendered) */}
      {showDetails && (
        <div className="bg-white shadow-md rounded-lg p-4 mt-4">
          <h3 className="text-lg font-semibold mb-2">More Store Details</h3>
          <div className="flex mb-4">
            <img
              src={businessImage}
              alt="Business"
              className="w-1/3 rounded-lg mr-4"
            />
          </div>

          <p className="text-gray-600">
            <span className="font-semibold">Business Types:</span>{' '}
            {businessInfo?.businessTypes.join(', ')}
          </p>
          {businessInfo?.ntn && (
            <div className="mt-2">
              <p className="text-gray-600 font-semibold">NTN:</p>
              <p className="text-gray-600">{businessInfo?.ntn}</p>
            </div>
          )}
          <div className="mt-2">
            <p className="text-gray-600 font-semibold">Store Timings:</p>
            {/* {console.log('businessInfo.timings.timings : ', businessTiming)} */}
            <div className="flex flex-wrap gap-4">
              {Object.keys(businessTiming)?.map(
                (day, index) =>
                  day !== '__typename' && (
                    <div
                      key={index}
                      className="flex-1 max-w-xs border border-gray-300 rounded-lg p-4"
                    >
                      <p className="text-lg font-semibold mb-2">{day}</p>
                      <div className="flex items-center text-gray-600">
                        <p className="mr-4">
                          <span className="font-semibold">Open:</span>{' '}
                          {formatTime(businessTiming[day][0]?.startTime)}
                        </p>
                        <p>
                          <span className="font-semibold">Closed:</span>{' '}
                          {formatTime(businessTiming[day][0]?.endTime)}
                        </p>
                      </div>
                    </div>
                  ),
              )}
            </div>
          </div>
          {/* <div className="mt-2">
            <p className="text-gray-600 font-semibold">
              Sales Tax Registration:
            </p>
            <p className="text-gray-600">{businessInfo.salesTax}</p>
          </div> */}

          <button
            onClick={() => setShowDetails(false)}
            className="bg-red-600 text-white rounded px-4 py-2 mt-4"
          >
            Show Less
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
