import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import { Animation } from './components/Animation';
import Login from './screens/Login';
import SignUp from './screens/Signup';
import ResetPassword from './screens/ResetPassword';
import Pricing from './components/Pricing';
import { useDispatch, useSelector } from 'react-redux';
import CustomerWebsite from './routes/customer';
import BusinessWebsite from './routes/business';
import { AuthProvider, useAuth } from './auth/authContext';
import Shop from './components/Shop';
import Products from './components/Products';
import { useEffect } from 'react';
import { GET_BUSINESS_BY_USER } from './GraphQL/mutation';
import { useQuery } from '@apollo/client';
import { setBusinessInfo } from './store/reducers/business';
import BestDeals from './components/BestDeals';
import ProductDetails from './components/ProductDetails';

function App() {
  const { webInterface } = useSelector((state) => state.main);
  // const { data } = useQuery(GET_BUSINESS_BY_USER);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!data || !data.getBusinessByUser) {
  //     dispatch(setBusinessInfo({}));
  //   } else {
  //     dispatch(setBusinessInfo(data));
  //   }
  // }, [data, dispatch]);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Animation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/products" element={<Products />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/pp/:slug" element={<ProductDetails />} />
          <Route path="/best-deals" element={<BestDeals />} />
          {/* {webInterface === 'customer' ? ( */}
          <Route path="/customer/*" element={<CustomerWebsite />} />
          {/* ) : ( */}
          <Route path="/business/*" element={<BusinessWebsite />} />
          {/* )} */}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
