import { configureStore } from '@reduxjs/toolkit';
import mainReducer from './reducers/main';
import userReducer from './reducers/user';
import businessReducer from './reducers/business';

const store = configureStore({
  reducer: {
    main: mainReducer,
    user: userReducer,
    business: businessReducer,
  },
});

export default store;
